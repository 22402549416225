<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <form
      class="profilehub-criteria-individual"
      v-else
      novalidate
      autocomplete="off"
      @submit.prevent="computedCreationPage ? createCriteria() : updateCriteria()"
    >
      <UiBanner large>
        <template v-slot:content>
          <div class="tw-flex">
            <v-btn small rounded icon elevation="0" class="tw-mr-2 tw--ml-2" @click="backToTable">
              <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            </v-btn>
            <UiTitle
              :title="`${$t('profilehub.criteria.individual.title', {
                criteria: computedCreationPage ? $t('profilehub.criteria.individual.new') : idCriteria.label,
              })}`"
              large
            />
          </div>
        </template>
      </UiBanner>
      <UiContainer with-actions large>
        <section>
          <UiTitle :title="$t('profilehub.criteria.individual.parameters.title')" />
          <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.subtitle')" />
        </section>
        <section class="tw-mt-6 tw-grid sm:tw-grid-cols-2 tw-gap-6">
          <div class="tw-flex tw-items-center">
            <div>
              <UiLabel
                id="active"
                class="tw-mr-4"
                :label="$t('profilehub.criteria.individual.parameters.field.active.label')"
              />
              <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.active.hint')" />
            </div>
            <v-spacer />
            <v-switch id="active" class="tw-ml-4 tw-mt-0" v-model="isActive" hide-details></v-switch>
          </div>
          <div class="tw-flex tw-items-center">
            <div>
              <UiLabel
                id="mandatory"
                class="tw-mr-4"
                :label="$t('profilehub.criteria.individual.parameters.field.mandatory.label')"
              />
              <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.mandatory.hint')" />
            </div>
            <v-spacer />
            <v-switch id="mandatory" class="tw-ml-4 tw-mt-0" v-model="isMandatory" hide-details></v-switch>
          </div>
          <div class="required-asterisk">
            <UiLabel id="label" :label="$t('profilehub.criteria.individual.parameters.field.label.label')" />
            <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.label.hint')" />
            <v-text-field
              id="label"
              :placeholder="$t('profilehub.criteria.individual.parameters.field.label.placeholder')"
              v-model="newLabel"
              single-line
              solo
              flat
              outlined
              :error-messages="newLabelErrors"
              @input="slugifyLabel"
            ></v-text-field>
          </div>
          <div class="tw--mt-6 sm:tw-mt-0">
            <UiLabel id="slug" :label="$t('profilehub.criteria.individual.parameters.field.slug.label')" />
            <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.slug.hint')" />
            <v-text-field
              class="tw-mt-0 tw-pt-0"
              id="slug"
              :label="computedCreationPage ? newSlug : idCriteria.slug"
              :placeholder="$t('profilehub.criteria.individual.parameters.field.slug.placeholder')"
              readonly
              disabled
              solo
              flat
              outlined
            ></v-text-field>
          </div>
        </section>
        <section class="tw-grid sm:tw-grid-cols-2 tw-gap-6">
          <div>
            <UiLabel id="type" :label="$t('profilehub.criteria.individual.parameters.field.type.label')" />
            <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.type.hint')" />
            <v-select
              id="type"
              v-model="selectedType"
              :placeholder="
                computedCreationPage
                  ? $t('profilehub.criteria.individual.parameters.field.type.placeholder')
                  : idCriteria.typeOptions
              "
              :items="computedOptions"
              solo
              flat
              :disabled="computedCreationPage ? false : true"
              outlined
            ></v-select>
          </div>
        </section>
        <section v-if="selectedType !== 'text' || (computedCreationPage && selectedType !== 'text')">
          <div class="profilehub-criteria-individual__combobox">
            <UiLabel id="tags" :label="$t('profilehub.criteria.individual.parameters.field.tags.label')" />
            <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.tags.hint')" />
            <v-combobox
              id="tags"
              v-model="addedTags"
              :placeholder="$t('profilehub.criteria.individual.parameters.field.tags.placeholder')"
              :label="$t('profilehub.criteria.individual.parameters.field.tags.placeholder')"
              multiple
              chips
              clearable
              deletable-chips
              solo
              flat
              outlined
              :error-messages="addedTagsErrors"
            ></v-combobox>
          </div>
          <div v-if="!computedCreationPage && tags.length" class="tw-mb-6 tw-flex tw-flex-col tw-items-center">
            <UiSubtitle :subtitle="$t('profilehub.criteria.individual.parameters.field.tags.existing')" />
            <v-card
              :class="
                !isTagsValid
                  ? 'profilehub-criteria-individual__card'
                  : 'tw-border-transparent tw-border-2 tw-border-solid'
              "
              :width="$vuetify.breakpoint.xsOnly ? '100%' : 300"
            >
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in tags" :key="i" @click="toggleStatus(item)">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.label"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch :input-value="item.status"></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
            <span v-if="!isTagsValid" class="profilehub-criteria-individual__error-message">{{
              $t('profilehub.criteria.individual.parameters.field.tags.validation')
            }}</span>
          </div>
        </section>
        <UiActions large>
          <v-btn color="primary" rounded text @click="backToTable">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" rounded type="submit" :disabled="creatingCriteria" :loading="creatingCriteria">
            {{ $t('button.validate') }}
          </v-btn>
        </UiActions>
      </UiContainer>
    </form>
  </v-fade-transition>
</template>

<script>
import { mdiArrowRight, mdiArrowLeft, mdiAlertCircleOutline, mdiTrashCanOutline } from '@mdi/js'
import { mapActions, mapState, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiLabel from '@/components/UI/Label.vue'
import { clone } from '@/utils/utilities.util'
import { stringToSlug } from '@/utils/utilities.util'

export default {
  name: 'ProfileHubCriteriaIndividual',

  components: {
    UiBanner,
    UiTitle,
    UiSubtitle,
    UiContainer,
    UiActions,
    UiLabel,
  },
  mixins: [validationMixin],
  data: () => ({
    selectedType: 'text',
    isMandatory: false,
    isActive: true,
    loading: false,
    creatingCriteria: false,
    newCriteria: null,
    newLabel: '',
    newSlug: '',
    tags: [],
    addedTags: [],
    icons: {
      mdiArrowRight,
      mdiArrowLeft,
      mdiAlertCircleOutline,
      mdiTrashCanOutline,
    },
  }),

  async created() {
    this.setUpdating(true)
    if (!this.computedCreationPage) {
      await this.getIdCriteria(this.$route.params.id)
      this.selectedType = this.idCriteria.typeOptions
      this.newLabel = this.idCriteria.label
      this.tags = clone(this.idCriteria.value)
      this.isActive = this.idCriteria.status
      this.isMandatory = this.idCriteria.required
    }

    this.setUpdating(false)
  },

  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      idCriteria: state => state.profilehub.idCriteria,
    }),
    ...mapGetters({
      currentBrandName: 'backoffice/currentBrandName',
    }),
    newLabelErrors() {
      const errors = []
      if (!this.$v.newLabel.$dirty) return errors
      !this.$v.newLabel.required && errors.push(this.$t('error.required'))
      return errors
    },
    addedTagsErrors() {
      const errors = []
      if (!this.$v.addedTags) return errors
      !this.$v.addedTags.required && errors.push(this.$t('error.required'))
      return errors
    },

    computedCreationPage() {
      return this.$route.name === 'ProfileHubCriteriaIndividualCreate'
    },
    computedOptions() {
      return [
        { value: 'text', text: this.$t('profilehub.criteria.individual.parameters.field.type.options.text') },
        {
          value: 'closedListUnique',
          text: this.$t('profilehub.criteria.individual.parameters.field.type.options.closedUnique'),
        },
        {
          value: 'closedListMultiple',
          text: this.$t('profilehub.criteria.individual.parameters.field.type.options.closedMultiple'),
        },
      ]
    },
    isTagsValid() {
      if (this.isActive) {
        const activeTags = this.tags.filter(tag => tag.status)
        return activeTags.length > 0
      }
      return true
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getIdCriteria: 'profilehub/getIdCriteria',
      postNewCriteria: 'profilehub/postNewCriteria',
      patchCriteria: 'profilehub/patchCriteria',
      setAlert: 'backoffice/setAlert',
    }),

    backToTable() {
      this.$router.push({ name: 'ProfileHubCriteriaList' })
    },
    deleteCriteria(item) {
      this.tags = this.tags.filter(tag => tag.label !== item.label)
    },
    createTag(tag) {
      return {
        label: tag,
      }
    },
    slugifyLabel(label) {
      if (this.computedCreationPage) {
        this.newSlug = `${stringToSlug(this.currentBrandName)}-${stringToSlug(label)}`
      }
    },

    async updateCriteria() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.creatingCriteria = true
        const updatedTags = this.tags.map(tag => {
          return { ...tag, status: +tag.status }
        })
        const newTagsList = this.addedTags.map(tag => this.createTag(tag))
        const criteria = {
          label: this.newLabel,
          required: +this.isMandatory,
          status: +this.isActive,
          value: updatedTags.concat(newTagsList),
        }
        try {
          await this.patchCriteria({ id: this.idCriteria.id, updatedCriteria: criteria })
          this.setAlert({
            color: 'success',
            text: this.$t('profilehub.criteria.individual.parameters.notification.success.update'),
          })
          this.backToTable()
        } catch {
          this.setAlert({
            color: 'error',
            text: this.$t('error.notification.default'),
          })
        }
        this.creatingCriteria = false
      }
    },

    createValue(tags) {
      return tags.map(tag => ({
        label: tag,
      }))
    },

    async createCriteria() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.creatingCriteria = true
        const newCriteria = {
          label: this.newLabel,
          typeOptions: this.selectedType,
          slug: this.newSlug,
          required: +this.isMandatory,
          status: +this.isActive,
          value: this.createValue(this.addedTags),
        }
        try {
          await this.postNewCriteria(newCriteria)
          this.setAlert({
            color: 'success',
            text: this.$t('profilehub.criteria.individual.parameters.notification.success.create'),
          })
          this.backToTable()
        } catch (error) {
          if (error.data.code === 'CRITERIA_SLUG_EXISTS') {
            this.setAlert({
              color: 'warning',
              text: this.$t('profilehub.criteria.individual.parameters.notification.error.noduplicate'),
            })
          } else {
            this.setAlert({
              color: 'error',
              text: this.$t('error.notification.default'),
            })
          }
        }
        this.creatingCriteria = false
      }
    },

    toggleStatus(item) {
      item.status = !item.status
    },
  },
  validations() {
    return {
      newLabel: {
        required,
      },
      addedTags: {
        required: requiredIf(() => {
          return this.selectedType !== 'text' && this.computedCreationPage
        }),
      },
    }
  },
}
</script>

<style lang="scss">
.profilehub-criteria-individual {
  &__combobox {
    .v-input__append-inner:last-of-type {
      display: none;
    }
  }
  &__card {
    @apply tw-mx-auto;

    border: 2px solid var(--v-error-base) !important;
  }
  &__error-message {
    margin-top: 0.25rem;
    line-height: 12px;
    color: var(--v-error-base);
    font-size: 0.75rem;
  }
}
</style>
