<template>
  <label :for="id" class="ui-label">
    <slot>{{ label }}</slot>
  </label>
</template>

<script>
export default {
  name: 'UiLabel',
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-label {
  @apply tw-text-sm tw-text-gray-500 dark:tw-text-gray-400 tw-font-medium tw-inline-flex tw-items-center;

  margin-bottom: 2px;
}
</style>
